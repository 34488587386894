import React from 'react'
import { Link } from 'react-router-dom'

const Post = ({post}) => {
  return (
    <article  className='mb-8 border-b-2 border-gray-200 text-center'>
        <Link to={`/post/${post.id}`}>

            <h2 className=' font-extrabold text-red-500'>{post.title}</h2>
            <p>{post.datetime}</p>
        </Link>
        <p> 
            {
            (post.body).length <= 25
             ? post.body : `${(post.body).slice(0, 25)}...` 
            }</p>
      
    </article>
  )
}

export default Post
