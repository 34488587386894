import React from 'react'
import Feed from './Feed'

const Home = ({posts }) => {
  return (
    // <div className='mt-4 ml-4 font-extrabold'>
    //   Home
    // </div>
    <main className='mt-4 ml-4 overscroll-auto'>
      {posts.length ? (
        <Feed posts = {posts}/>
      ) : (
        <p style={{marginTop: '2rem'}}>
          No Posts to display.
        </p>
      )}

    </main>
  )
}

export default Home

