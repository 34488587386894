import React, { useState, useEffect} from 'react'
import  {  Routes, Route, useNavigate} from "react-router-dom"
import { format } from 'date-fns';
import api from './api/posts'


//import Home from './Pages/Home'
// import BookList from './Pages/BookList'
// import Book from './Pages/Book'
// import About from './Components/About'
//import Home from './Components/Home'
import NewPost from './Components/NewPost'
import Missing from './Components/Missing'
import Footer from './Components/Footer'
import Header from './Components/Header'
import Home from './Components/Home'
import Nav from './Components/Nav'
import PostPage from './Components/PostPage'
import EditPost from './Components/EditPost';
import About from './Components/About'


 
 
const Appp = () => {
    const [posts, setPosts] = useState([

    ]);
    const [search, setSearch] = useState('')

    const [searchResults, setSearchResults] =useState([]);
    const [ postTitle, setPostTitle] = useState('');
    const [ postBody, setPostBody] = useState('');
    const [editTitle, setEditTitle] = useState('');
    const [editBody, setEditBody] = useState ('');
    const  navigate = useNavigate();

    useEffect(()  =>  {
        const fetchPosts = async () => {
          try {
            const response = await api.get('/posts');
            // if (response && response.data) setPosts(response.data)
            setPosts(response.data);
          } catch (err) {
            if(err.response){
                //Not in the 200 response range
                console.log(err.response.data);
                console.log(err.response.status);
                console.log(err.response.header);
            } else {
              console.log ( `Error: ${err.message}`);
            }
          }
        };
           fetchPosts();

    },  []);
    
    useEffect(() => {
        const filteredResults = posts.filter(post =>
            ((post.body).toLowerCase()).includes(search.toLowerCase())
            || ((post.title).toLowerCase()).includes(search.toLowerCase()) ||
            ((post.datetime).toLowerCase()).includes(search.toLowerCase()) );

            setSearchResults(filteredResults.reverse());
            
    }, [posts, search]

    ) ;
            
 
        
     
    const handleSubmit = async (e) => {
            e.preventDefault();
              const id = posts.length ? posts[posts.length-1].id + 1 : 1;
              const datetime = format(new Date(),  'MMMMM dd, yyyy pp');
              const newPost = {id, title: postTitle, datetime, body: postBody};
              // create post in the  CRUD
           
            try {
              const response =  await api.post('/posts', newPost); 
              const allPosts = [ ...posts, response.data];
              setPosts(allPosts);
              setPostTitle('');
              setPostBody('');
              navigate('/');
            } catch (err) {
              console.log(`Error: ${err.message}`);
            } 
          
        }
    const handleEdit = async (id) => {
          const datetime = format(new Date(),  'MMMMM dd, yyyy pp');
          const updatedPost = {id, title: editTitle, datetime, body: editBody};
          try{
            const response = await api.put(`/posts/${id}`, updatedPost);
            setPosts(posts.map(post => post.id === id ? { ...response.data} : post));

            setEditTitle('');
            setEditBody('');
            navigate('/');
            } catch (err) {
            console.log(`Error: ${err.message}` ) ;
            }
        
        }

    const handleDelete = async (id) => {
          try{
            await api.delete(`/posts/${id}`);
            const postsList = posts.filter(  post => post.id !== id);
            setPosts(postsList);
            navigate('/');
            } catch (err) {
              console.log(`Error: ${err.message}`) ;
            }
          }
       

  return (
        <div>
        
        <nav>
                
            </nav>
          
          <Header title ="React Js Blog"/>
          <Nav search={search} setSearch={setSearch}/>

                {/* <Route path='/' element={<Home/>}/>     
                <Route path='/books' element={<BookList/>}/>
                <Route path='/books/:id' element={<Book/>}/> */}
                {/* <Route path='/' element={<Home/>}/>  */}
              <Routes >
                <Route path='/' element={<Home posts={searchResults}/>}/> 
                <Route path='/post' element={<NewPost
                  handleSubmit={handleSubmit}
                  postTitle={postTitle}
                  setPostTitle={setPostTitle}
                  postBody={postBody}
                  setPostBody={setPostBody}
                />}/> 

                <Route path='/edit/:id' element={<EditPost
                  posts={posts}
                  handleEdit={handleEdit}
                  editTitle={editTitle}
                  setEditTitle={setEditTitle}
                  editBody={editBody}
                  setEditBody={setEditBody}
                />}/> 

                <Route path='/post/:id' 
                 element={<PostPage posts={posts} handleDelete={handleDelete}/>}/> 
                 
                <Route path='*' element={<Missing/>}/>   
                <Route path='/about' element={<About/>}/>
            </Routes>
            <Footer/>

        


        </div>
  )
}

export default Appp
