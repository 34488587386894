import React from 'react'
import { Link } from 'react-router-dom'

const Nav = ({ search, setSearch}) => {
  return (
    <nav className='flex flex-row items-center justify-around text-gray-300 bg-black w-full h-16'>
      <form className=' ' onSubmit={(e) => e.preventDefault()}>
        <label  
        className=''
        htmlFor='search'></label>
        <input
            id='search'
            type='text'
            placeholder= 'Search Posts'
            value={search}
            onChange={(e)  => setSearch(e.target.value) }
            className='  sm:w-[140] md:w-[200%] lg:w-[400%] rounded h-8  text-lime-950'
        />
      </form>
        <div className=''>
          <ul className=' flex md:ml-[20%] lg:ml-[60%] space-x-4'>
            <li className=' hover:underline font-semibold' > <Link to= "/">Home</Link></li>
            <li className=' hover:underline font-semibold'> <Link to= "/post">NewPost</Link></li>            
            <li className=' hover:underline  font-semibold'> <Link to= "/about">About</Link></li>
  </ul>
</div>
    </nav>
  )
}

export default Nav
