import React from 'react'

const Header = ({title}) => {
  return (
    <div className='  font-bold bg-sky-400 hover:bg-gray-400  w-full h-20 flex items-center text-4xl'>
      <h1 className=' ml-8 font-bold'> {title}</h1>
    </div>
  )
}

export default Header
