import React from 'react'
import { useParams, Link } from 'react-router-dom';
 
const PostPage = ({ posts, handleDelete}) => {
  const { id } = useParams();
  const post = posts.find(post => (post.id).toString() === id);
  return (
  <div className=' mt-8 ml-2'>
      <article className=''>
            {post &&
              <>
                <h2 className=' font-bold text-2xl'>{post.title}</h2>
                <p className=' mt-3'>{post.datetime}</p>
                <p className=' mt-3 mb-3'>{post.body}</p>
                <Link to={`/edit/${post.id}`}> <button className=' bg-yellow-500 hover:bg-green-900 text-stone-800 hover:text-gray-50 mr-6 '>Edit Post</button></Link>
                <button onClick={() => handleDelete(post.id)}   className=' hover:bg-slate-600 bg-red-500'>Delete Post</button>
              
              </>
              
            }
            {!post &&
              <div className=' text-center font-semibold mt-10 pt-10 text-2xl my-20'>
                <h2 className=' font-thin text-red-600 '> Post not found </h2>
                <p>well, that's disappointing</p>
                <p className=' underline cursor-pointer'> <Link to='/' > visit our homepage </Link>
                </p>
              </div>

            }
      </article>
  </div>
  )
}

export default PostPage
