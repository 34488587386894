import React from 'react'
import { useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'

const EditPost = ({
    posts, handleEdit, editBody, setEditBody, editTitle, setEditTitle
}) => {
  const {id} = useParams();
  const post = posts.find( post => (post.id).toString() === id);


  useEffect(()=> {
    if (post) {
        setEditTitle(post.title);
        setEditBody(post.body);

    }

  },  [post, setEditBody, setEditTitle])
  return (
    <div className=' mx-6'>
        {editTitle && 
            <>
            <h2 className=' font-bold'>  Edit post  </h2>
            <form className='' onSubmit={ (e) => e.preventDefault()}>
                <div  className=' mt-4 mb-4'>
                    <label htmlFor='editTitle'> Title </label>
                    <br/>
                
                    <input
                        id='editTitle'
                        type='text'
                        required
                        value={editTitle}
                        onChange={ (e) => setEditTitle(e.target.value)}
                        className='w-full h-12 border-2 '
                    />
                    </div>
                    <div className=' mb-4'>
                    <label  htmlFor='editBody'> Post: </label>
                    <br/>
                    
                    <textarea
                        id='editBody'
                        required
                        value={editBody}
                        onChange={ (e) => setEditBody(e.target.value)}
                        className=' w-full h-40  border-2'
                    
                    />
                    </div>
                    <button 
                    className=' rounded-sm w-full bg-slate-400 hover:bg-orange-950 hover:text-slate-100'
                    type='submit'
                    onClick= { (e) => handleEdit(post.id)}
                    > Submit </button>
            
            </form>
            </>
        }
        { !editTitle &&
        <div className=' text-center font-semibold mt-10 pt-10 text-2xl my-20'>
            <h2 className=' font-thin text-red-600 '> Post not found </h2>
            <p>well, that's disappointing</p>
            <p className=' underline cursor-pointer'> <Link to='/' > visit our homepage </Link>
            </p>
        </div>

        }

    </div>
  )
}

export default EditPost
