import React from 'react'
import { Link } from 'react-router-dom'

const Missing = () => {
  return (
    <div className=' mt-20 ml-3 text-center'> 
      Not existing
      <h2 className=' font-semibold text-red-500 text-3xl'> Page not found</h2>
      <p className=' font-medium text-pink-300'> well, that's disappointing 2</p>
      <p className=' underline '>
          <Link to='/'> Visit Our Homepage!</Link>
      </p>
    </div>
  )
}   

export default Missing
