import React from 'react'

const About = () => {
  return (
    <div className='mt-4'>
      <h1>  About turn</h1>
      <p> This blog app is a project in the domain that may attracts any kind of upgraded and updated amendment for betterment in productivity</p>
    </div>
  )
}

export default About
