import React from 'react'

const Footer = () => {
  const today = new Date();
  return (
    <div className=' bottom-0 lg:mt-[5%] mt-[7%]  md:mt-[10%] w-full h-12 hover:bg-gray-400  bg-sky-400 bgtext-center text-xl font-extrabold justify-center items-center flex fixed'>
    
      <p className='text-2xl p-4 '> Copyright &copy; {today.getFullYear()}</p>
    </div>
  )
}

export default Footer
