import React from 'react'

const NewPost = ({  handleSubmit, postTitle, setPostTitle, postBody, setPostBody}) => {
  return (
    <div className='mt-4 ml-3 mr-2'>
      <h2 className=' font-bold'>  Newpost  </h2>
      <form className='' onSubmit={handleSubmit}>
      <div  className=' mt-4 mb-4'>
          <label htmlFor='postTitle'> Title </label>
          <br/>
       
          <input
              id='postTitle'
              type='text'
              required
              value={postTitle}
              onChange={ (e) => setPostTitle(e.target.value)}
              className='w-full h-12 border-2 '
          />
        </div>
        <div className=' mb-4'>
          <label  htmlFor='postBody'> Post: </label>
          <br/>
        
            <textarea
                id='postBody'
                required
                value={postBody}
                onChange={ (e) => setPostBody(e.target.value)}
                className=' w-full h-40  border-2'
            
            />
        </div>
          <button 
          className=' rounded-sm w-full bg-slate-400 hover:bg-orange-950 hover:text-slate-100'
          type='submit'> Submit </button>
     
      </form>
    </div>
  )
}

export default NewPost
